<template>
  <div class="filter-holder" v-if="showFilter">
    <div :class="`filter ${currentTag.value == null ? 'selected' : ''}`" @click="updateCurrentTag(null)">All</div>
    <div
      :class="`filter ${currentTag.value == tag ? 'selected' : ''}`"
      v-for="(tag, i) in tags"
      v-html="tag"
      :key="i"
      @click="updateCurrentTag(tag)"
    ></div>
  </div>
</template>
<style lang="scss" scoped>
.filter-holder {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
  .filter {
    padding: 0.5em 1em;
    border-radius: 20px;
    background-color: var(--theme-color-6);
    color: var(--theme-contrast-6);
    font-family: var(--font-family);
    font-size: 1em;
    cursor: pointer;
  }
  .selected {
    background-color: var(--theme-color);
    color: var(--theme-contrast);
  }
}
</style>
<script lang="ts">
import { SetQueryParameter, RemoveQueryParameter, GetQueryParameter } from "@/services/ParameterServices"
import { PropType, defineComponent, Ref, computed } from "vue"
export default defineComponent({
  props: {
    tags: { type: Object as PropType<Array<string>>, required: false, default: null },
    currentTag: { type: Object as PropType<Ref<string | null>>, required: false, default: null },
    pageQueryParam: { type: String, required: true, default: "galleryPage" },
    resetPage: { type: Function, required: true }
  },
  setup(params) {
    const QUERY_VARIABLE = "galleryTag"
    var showFilter = computed(() => {
      if (params.tags && params.tags.length > 0) return true
      return false
    })
    return { showFilter, QUERY_VARIABLE }
  },
  mounted() {
    var tag = GetQueryParameter(this.QUERY_VARIABLE)
    if (this.tags && this.tags.indexOf(tag ?? "") > -1) {
      this.currentTag.value = tag
    }
  },
  methods: {
    updateCurrentTag(tag: string | null) {
      this.currentTag.value = tag
      this.resetPage()
      this.updateQueryParam()
    },
    updateQueryParam() {
      if (this.currentTag.value) SetQueryParameter(this.QUERY_VARIABLE, this.currentTag.value, [this.pageQueryParam])
      else RemoveQueryParameter([this.QUERY_VARIABLE, this.pageQueryParam])
    }
  }
})
</script>

import HubInfo from "../models/hub/HubInfo"
export function preProcessHubData(info: HubInfo | null): HubInfo | null {
  if (info == null) return null
  var webinars = info.webinars

  var pinnedWebinars = webinars.filter(x => x.is_pinned === true)
  var unpinnedWebinars = webinars.filter(x => !x.is_pinned)
  var newWebinars = [...pinnedWebinars, ...unpinnedWebinars]
  info.webinars = newWebinars
  return info
}


import { defineComponent, PropType, Ref } from "vue"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"

export default defineComponent({
  props: {
    url: { type: String, required: true }
  },
  setup() {
    const { translateLabels } = injectStrict(translationKey)
    return { translateLabels }
  }
})

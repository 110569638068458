<template>
  <div>
    <div class="video-panel" v-for="i in 5" :key="i">
      <div class="video-holder">
        <div class="loading-video">
          <svg
            class="loading-svg-icon loading-svg-icon-small shaka-big-play"
            viewBox="0 0 61 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="61" height="61" rx="12" fill="#ccc" />
            <path
              fill="#fff"
              d="M22 21.5763C22 19.5799 24.1634 18.3445 25.8688 19.367L40.752 28.2906C42.416 29.2884 42.416 31.7115 40.752 32.7092L25.8688 41.633C24.1635 42.6555 22 41.4201 22 39.4237L22 21.5763Z"
            />
          </svg>
          <div class="loading-message">{{ loadingmsg }}</div>
        </div>
      </div>
      <div class="info-holder">
        <div class="skeleton-title skeleton"></div>
        <div class="skeleton-paragraph skeleton"></div>
        <div class="skeleton-paragraph skeleton"></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../../assets/skeleton.css";

.video-panel {
  p {
    margin: 0px 0px;
  }
  border-radius: 24px;
  display: flex;
  flex-flow: row;
  padding: 32px;
  margin-top: 32px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
}
.video-holder {
  width: calc(50% - 20px);
  margin: 10px;
  margin-right: 20px;
  border-radius: 15px;
  overflow: hidden;
  height: fit-content;
  border: 1px solid #bdbdbd88;
}
.info-holder {
  width: calc(50% - 20px);
  display: flex;
  padding-top: 10px;
  flex-direction: column;
}

@media (max-width: 1050px) and (min-width: 600px) {
  .video-panel {
    border-radius: 12px;
    flex-flow: column;
    padding: 15px;
    .video-holder {
      width: calc(100% - 20px);
      margin: 10px;
    }
    .info-holder {
      width: calc(100% - 20px);
    }
  }
}
@media (max-width: 600px) {
  .video-panel {
    border-radius: 12px;
    flex-flow: column;
    padding: 10px;

    .video-holder {
      width: calc(100% - 20px);
      margin: 10px;
    }
    .info-holder {
      width: calc(100% - 20px);
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core"
export default defineComponent({
  props: {
    loadingmsg: { type: String, required: false, default: "" }
  }
})
</script>


import { SetQueryParameter, RemoveQueryParameter, GetQueryParameter } from "@/services/ParameterServices"
import { PropType, defineComponent, Ref, computed } from "vue"
export default defineComponent({
  props: {
    tags: { type: Object as PropType<Array<string>>, required: false, default: null },
    currentTag: { type: Object as PropType<Ref<string | null>>, required: false, default: null },
    pageQueryParam: { type: String, required: true, default: "galleryPage" },
    resetPage: { type: Function, required: true }
  },
  setup(params) {
    const QUERY_VARIABLE = "galleryTag"
    var showFilter = computed(() => {
      if (params.tags && params.tags.length > 0) return true
      return false
    })
    return { showFilter, QUERY_VARIABLE }
  },
  mounted() {
    var tag = GetQueryParameter(this.QUERY_VARIABLE)
    if (this.tags && this.tags.indexOf(tag ?? "") > -1) {
      this.currentTag.value = tag
    }
  },
  methods: {
    updateCurrentTag(tag: string | null) {
      this.currentTag.value = tag
      this.resetPage()
      this.updateQueryParam()
    },
    updateQueryParam() {
      if (this.currentTag.value) SetQueryParameter(this.QUERY_VARIABLE, this.currentTag.value, [this.pageQueryParam])
      else RemoveQueryParameter([this.QUERY_VARIABLE, this.pageQueryParam])
    }
  }
})

<template>
  <div class="video-panel">
    <p class="title clamp-lines" v-html="webinar.title"></p>
    <div class="video-holder" :key="webinar.id">
      <VideoPlayer
        :poster="webinar.trailer.video_urls.poster_url"
        :video_urls="webinar.trailer.video_urls"
        :subtitles="webinar.trailer.subtitles"
        :duration="webinar.trailer.duration"
        :videoType="ContentType.Trailer"
        :title="webinar.title"
        :useDemoOverlay="useDemoOverlay"
        :logoOverlay="webinar.overlay_logo"
        :isTrailer="true"
        :webinarId="webinar.id"
      />
    </div>
    <div class="info-holder">
      <p
        :title="webinar.title"
        class="title clamp-lines"
        v-html="webinar.title"
        @click="naviateToLP(`${webinar.landing_page_url}`)"
      ></p>
      <div class="description-wrapper">
        <p class="description clamp-lines" v-html="webinar.description"></p>
        <div
          class="read-more-container"
          title="Visit page"
          v-if="webinar.description ? (webinar.description.length < 232 ? false : true) : false"
          @click="naviateToLP(`${webinar.landing_page_url}`)"
        >
          Read more
        </div>
        <div class="watch-full-video-btn-wrapper" v-if="!hasMoments">
          <WatchFullVideoButton :url="webinar.landing_page_url" />
        </div>
      </div>
      <div class="flex-grow" v-if="hasMoments"></div>
      <p class="key-moments" v-if="hasMoments">Key Moments</p>
      <div class="holder" v-if="hasMoments">
        <div class="right btn" @click="scrollRight()" v-if="showScroll">
          <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15.8137" r="15" fill="#E6E6E6" />
            <path
              d="M13.0271 21.3094C12.7075 21.6112 12.2081 21.6112 11.8886 21.3094C11.542 20.9822 11.542 20.4308 11.8886 20.1036L16.8116 15.4553L11.8886 10.8071C11.542 10.4798 11.542 9.92845 11.8886 9.60121C12.2081 9.2995 12.7075 9.2995 13.0271 9.60121L19.2273 15.4553L13.0271 21.3094Z"
              fill="#575757"
            />
          </svg>
        </div>
        <div class="left btn" @click="scrollLeft()" v-if="showScroll">
          <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15.8137" r="15" fill="#E6E6E6" />
            <path
              d="M13.0271 21.3094C12.7075 21.6112 12.2081 21.6112 11.8886 21.3094C11.542 20.9822 11.542 20.4308 11.8886 20.1036L16.8116 15.4553L11.8886 10.8071C11.542 10.4798 11.542 9.92845 11.8886 9.60121C12.2081 9.2995 12.7075 9.2995 13.0271 9.60121L19.2273 15.4553L13.0271 21.3094Z"
              fill="#575757"
            />
          </svg>
        </div>
        <div class="slider" ref="scrollContainer">
          <div class="track">
            <div
              @click="naviateToLP(`${webinar.landing_page_url}?moment=${m.id}`)"
              class="moment"
              v-for="(m, i) in webinar.moments"
              :key="i"
            >
              <div class="relative">
                <div class="image" :style="`background-image:url(${m.poster_url}) !important`" />
                <div class="duration" v-html="formatDate(m.duration)"></div>
              </div>
              <p :title="m.title" class="moment-title clamp-lines" v-html="m.title"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.relative {
  width: 100%;
}
.video-panel {
  p {
    margin: 0px 0px;
  }
  border-radius: 24px;
  display: flex;
  flex-flow: row;
  padding: 32px;
  margin-top: 32px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  .clamp-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .title {
    display: none;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1.625em;
    line-height: normal;
    color: var(--font-color);
  }
  .video-holder {
    width: calc(50% - 20px);
    margin: 10px;
    margin-right: 20px;
    border-radius: 15px;
    overflow: hidden;
    height: fit-content;
    border: 1px solid #bdbdbd88;
  }
  .info-holder {
    width: calc(50% - 20px);
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    .moment {
      width: 180px;
      flex-shrink: 0;
      text-decoration: none;
      -webkit-user-drag: none;
      cursor: pointer;
    }
    .title {
      display: -webkit-box;
      color: var(--font-color);
      font-family: var(--font-family);
      font-weight: 700;
      font-size: 1.25em;
      line-height: normal;
      margin: 0em;
      padding: 0px;
      cursor: pointer;
    }
    .description {
      color: var(--font-color);
      font-family: var(--font-family);
      font-style: normal;
      font-weight: 400;
      font-size: 1em;
      line-height: normal;
      margin: 0em 0px;
      padding: 0px;
      cursor: pointer;
      margin-top: 0.25em;
      p {
        margin: 0px;
      }
    }
    .flex-grow {
      display: block;
      flex-grow: 1;
    }
    .key-moments {
      font-size: 1em;
      line-height: normal;
      font-family: var(--font-family);
      color: var(--font-color);
      font-weight: 600;
      margin: 0em 0em;
      display: block;
      margin-top: 0.5em;
    }
    .moment-title {
      font-family: var(--font-family);
      font-style: normal;
      font-weight: 400;
      font-size: 0.75em;
      line-height: normal;
      color: var(--font-color);
      display: -webkit-box;
      cursor: pointer;
      margin: 0.25em 0px !important;
    }
  }
}

.slider {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: fit-content;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  .track {
    display: flex;
    column-gap: 18px;
    width: fit-content;
    margin-left: -18px;
  }

  .image {
    width: 180px;
    height: 101px;
    border: 1px solid #b7b7b7;
    border-radius: 10px;
    cursor: pointer;
    background-size: contain;
  }
  .duration {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875em;
    line-height: normal;
    color: #ffffff;
    background-color: var(--font-color);
    position: absolute;
    right: 5px;
    bottom: 8px;
    padding: 4px 4px;
    border-radius: 4px;
    text-transform: lowercase;
  }
}

.slider::-webkit-scrollbar {
  display: none;
}
.holder {
  width: 100%;
  position: relative;
  margin-top: 10px;
}
.btn {
  position: absolute;
  display: flex;
  height: 101px;
  top: 0px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  svg {
    background-color: #e6e6e6;
    border-radius: 360px;
    width: 30px;
    height: 30px;
  }
}
.left {
  left: 0px;
  svg {
    transform: translateX(-50%) rotate(-180deg);
  }
  svg:hover {
    transform: translateX(-50%) rotate(-180deg) scale(1.2);
  }
}
.right {
  right: 0px;
  svg {
    transform: translateX(50%);
  }
  svg:hover {
    transform: translateX(50%) scale(1.2);
  }
}

@media (max-width: 1050px) and (min-width: 600px) {
  .video-panel {
    border-radius: 12px;
    flex-flow: column;
    padding: 15px;
    .title {
      display: -webkit-box;
      margin: 0px 0.25em;
      font-family: var(--font-family);
      font-style: normal;
      font-weight: 700;
      font-size: 1.125em;
      line-height: normal;
    }
    .holder {
      .btn {
        height: 68px;
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
    .slider {
      .track {
        column-gap: 16px;
        margin-left: -16px;
      }
      .moment {
        width: 120px;
      }
      .image {
        width: 120px;
        height: 68px;
      }
    }
    .video-holder {
      width: calc(100% - 20px);
      margin: 10px;
    }
    .info-holder {
      width: calc(100% - 20px);
      .description {
        display: none;
      }
      .read-more-container {
        display: none;
      }
      .flex-grow {
        display: none;
      }
      .title {
        display: none;
      }
      .key-moments {
        display: block;
      }
    }
  }
}
@media (max-width: 600px) {
  .video-panel {
    border-radius: 12px;
    flex-flow: column;
    padding: 10px;

    .title {
      display: -webkit-box;
      margin: 0px 0.25em;
      font-family: var(--font-family);
      font-style: normal;
      font-weight: 700;
      font-size: 1.125em;
      line-height: normal;
    }
    .slider {
      .track {
        column-gap: 16px;
        margin-left: -16px;
      }
      .moment {
        width: 85px;
      }
      .image {
        width: 85px;
        height: 48px;
      }
    }
    .holder {
      .btn {
        height: 48px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .video-holder {
      width: calc(100% - 20px);
      margin: 10px;
    }
    .info-holder {
      width: calc(100% - 20px);
      .description {
        display: none;
      }
      .read-more-container {
        display: none;
      }
      .flex-grow {
        display: none;
      }
      .title {
        display: none;
      }
      .key-moments {
        display: block;
      }
    }
  }
}

.read-more-container {
  font-size: var(--font-size);
  font-family: var(--font-family);
  color: var(--font-color);
  cursor: pointer;
}

.description-wrapper {
  position: relative;
}
.watch-full-video-btn-wrapper {
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin: auto;
  margin-top: 25%;
  align-items: center;
}
</style>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue"
import HubWebinar from "@/models/hub/HubWebinar"
import VideoPlayer from "@/components/shared/VideoPlayer.vue"
import { ContentType } from "@/models/ContentType"
import { formatDate } from "@/services/DurationFormatter"
import WatchFullVideoButton from "@/components/HubComponent/WatchFullVideoButton.vue"

export default defineComponent({
  components: { VideoPlayer, WatchFullVideoButton },
  props: {
    webinar: { type: Object as PropType<HubWebinar>, required: true },
    useDemoOverlay: { type: Boolean, required: false, default: false }
  },
  setup(props) {
    let pos = { top: 0, left: 0, x: 0, y: 0 }
    const scrollContainer = ref<HTMLElement | null>()
    var was_dragger = false
    let showScroll = computed(() => props.webinar.moments.length > 2)
    let hasMoments = computed(() => !!props.webinar.moments && props.webinar.moments.length > 0)
    return { ContentType, formatDate, scrollContainer, pos, was_dragger, showScroll, hasMoments }
  },
  mounted() {
    this.addScroller()
  },
  methods: {
    addScroller() {
      var self = this
      self.scrollContainer?.addEventListener("mousedown", (e: any) => mouseDownHandler(e))
      const mouseDownHandler = function (e: any) {
        if (!self.scrollContainer) return
        self.pos = {
          // The current scroll
          left: self.scrollContainer.scrollLeft,
          top: self.scrollContainer.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY
        }
        self.scrollContainer.style.cursor = "grabbing"
        self.scrollContainer.style.userSelect = "none"
        document.addEventListener("mousemove", self.mouseMoveHandler)
        document.addEventListener("mouseup", self.mouseUpHandler)
      }
    },
    naviateToLP(url: string) {
      if (!this.was_dragger) window.location.assign(url)
      else this.was_dragger = false
    },
    mouseMoveHandler(e: any) {
      if (!this.scrollContainer) return
      // How far the mouse has been moved
      const dx = e.clientX - this.pos.x
      const dy = e.clientY - this.pos.y
      if (dx > 5 || dx < -5) {
        this.was_dragger = true
      }
      // Scroll the element
      //this.scrollContainer.scrollTop = pos.top - dy;
      this.scrollContainer.scrollLeft = this.pos.left - dx
    },
    mouseUpHandler() {
      document.removeEventListener("mousemove", this.mouseMoveHandler)
      document.removeEventListener("mouseup", this.mouseUpHandler)
      if (!this.scrollContainer) return
      this.scrollContainer.style.cursor = "default"
      this.scrollContainer.style.removeProperty("user-select")
    },

    scrollRight() {
      if (!this.scrollContainer) return
      this.scrollContainer.scrollLeft =
        this.scrollContainer.scrollLeft + this.scrollContainer.scrollWidth / (this.webinar.moments.length ?? 1)
    },
    scrollLeft() {
      if (!this.scrollContainer) return
      this.scrollContainer.scrollLeft =
        this.scrollContainer.scrollLeft - this.scrollContainer.scrollWidth / (this.webinar.moments.length ?? 1)
    }
  }
})
</script>

<template>
  <div>
    <a type="button" role="button" target="_blank" class="pw-watch-video-play-button" :href="url">
      <span class="wath-trailer-txt">Watch Video</span>
      <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_18651_8462)">
          <path
            d="M10.6245 17.2088H2.31295C1.0374 17.2088 0 16.1708 0 14.8958V6.11581C0 4.84026 1.03796 3.80286 2.31295 3.80286H8.55984C8.96311 3.80286 9.29001 4.12975 9.29001 4.53303C9.29001 4.9363 8.96311 5.2632 8.55984 5.2632H2.31295C1.84284 5.2632 1.46034 5.64569 1.46034 6.11581V14.8958C1.46034 15.3659 1.84284 15.7484 2.31295 15.7484H10.6245C11.0946 15.7484 11.4771 15.3659 11.4771 14.8958V8.61524C11.4771 8.21196 11.804 7.88507 12.2073 7.88507C12.6106 7.88507 12.9375 8.21196 12.9375 8.61524V14.8958C12.9375 16.1714 11.8995 17.2088 10.6245 17.2088Z"
          />
          <path
            d="M6.08379 11.5876C5.90012 11.5876 5.71702 11.519 5.57492 11.3809C5.28566 11.1 5.27948 10.6372 5.56031 10.3485L14.5785 1.07199C14.8593 0.782729 15.3216 0.77655 15.6108 1.05738C15.9001 1.33822 15.9063 1.80047 15.6254 2.08973L6.60727 11.3663C6.46404 11.5134 6.2742 11.5876 6.08379 11.5876Z"
          />
          <path
            d="M15.1846 7.63901C14.7814 7.63901 14.4545 7.31212 14.4545 6.90884V2.25148H9.86396C9.46068 2.25148 9.13379 1.92459 9.13379 1.52131C9.13379 1.11803 9.46068 0.791138 9.86396 0.791138H15.1846C15.5879 0.791138 15.9148 1.11803 15.9148 1.52131V6.90884C15.9148 7.31212 15.5879 7.63901 15.1846 7.63901Z"
          />
        </g>
      </svg>
    </a>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, Ref } from "vue"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"

export default defineComponent({
  props: {
    url: { type: String, required: true }
  },
  setup() {
    const { translateLabels } = injectStrict(translationKey)
    return { translateLabels }
  }
})
</script>

<style lang="scss" scoped>
.pw-watch-video-play-button:active {
  background: none;
}

.pw-watch-video-play-button {
  all: unset;
  display: flex;
  align-items: baseline;
  background: none;
  cursor: pointer;
  padding: 4px;
  border: 1px solid;
  border-color: var(--theme-color);
  color: var(--theme-color);
  text-decoration: none;
  display: flex;
  opacity: 0.9;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  .wath-trailer-txt {
    margin-left: unset;
    margin-right: 8px;
    color: #333;
  }
  svg {
    fill: #333;
  }

  &:hover {
    .wath-trailer-txt {
      color: var(--theme-color);
    }

    svg {
      fill: var(--theme-color);
    }
  }
}

.pw-player-playBtn-holder:hover {
  .shaka-big-play rect {
    fill-opacity: 1;
  }
  .shaka-big-play {
    transform: scale(90%);
  }
  .pw-trailer-play-button {
    background-color: var(--theme-color);
    opacity: 1;
  }
}
</style>

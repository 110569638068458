
import { computed, defineComponent, PropType, ref } from "vue"
import HubWebinar from "@/models/hub/HubWebinar"
import VideoPlayer from "@/components/shared/VideoPlayer.vue"
import { ContentType } from "@/models/ContentType"
import { formatDate } from "@/services/DurationFormatter"
import WatchFullVideoButton from "@/components/HubComponent/WatchFullVideoButton.vue"

export default defineComponent({
  components: { VideoPlayer, WatchFullVideoButton },
  props: {
    webinar: { type: Object as PropType<HubWebinar>, required: true },
    useDemoOverlay: { type: Boolean, required: false, default: false }
  },
  setup(props) {
    let pos = { top: 0, left: 0, x: 0, y: 0 }
    const scrollContainer = ref<HTMLElement | null>()
    var was_dragger = false
    let showScroll = computed(() => props.webinar.moments.length > 2)
    let hasMoments = computed(() => !!props.webinar.moments && props.webinar.moments.length > 0)
    return { ContentType, formatDate, scrollContainer, pos, was_dragger, showScroll, hasMoments }
  },
  mounted() {
    this.addScroller()
  },
  methods: {
    addScroller() {
      var self = this
      self.scrollContainer?.addEventListener("mousedown", (e: any) => mouseDownHandler(e))
      const mouseDownHandler = function (e: any) {
        if (!self.scrollContainer) return
        self.pos = {
          // The current scroll
          left: self.scrollContainer.scrollLeft,
          top: self.scrollContainer.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY
        }
        self.scrollContainer.style.cursor = "grabbing"
        self.scrollContainer.style.userSelect = "none"
        document.addEventListener("mousemove", self.mouseMoveHandler)
        document.addEventListener("mouseup", self.mouseUpHandler)
      }
    },
    naviateToLP(url: string) {
      if (!this.was_dragger) window.location.assign(url)
      else this.was_dragger = false
    },
    mouseMoveHandler(e: any) {
      if (!this.scrollContainer) return
      // How far the mouse has been moved
      const dx = e.clientX - this.pos.x
      const dy = e.clientY - this.pos.y
      if (dx > 5 || dx < -5) {
        this.was_dragger = true
      }
      // Scroll the element
      //this.scrollContainer.scrollTop = pos.top - dy;
      this.scrollContainer.scrollLeft = this.pos.left - dx
    },
    mouseUpHandler() {
      document.removeEventListener("mousemove", this.mouseMoveHandler)
      document.removeEventListener("mouseup", this.mouseUpHandler)
      if (!this.scrollContainer) return
      this.scrollContainer.style.cursor = "default"
      this.scrollContainer.style.removeProperty("user-select")
    },

    scrollRight() {
      if (!this.scrollContainer) return
      this.scrollContainer.scrollLeft =
        this.scrollContainer.scrollLeft + this.scrollContainer.scrollWidth / (this.webinar.moments.length ?? 1)
    },
    scrollLeft() {
      if (!this.scrollContainer) return
      this.scrollContainer.scrollLeft =
        this.scrollContainer.scrollLeft - this.scrollContainer.scrollWidth / (this.webinar.moments.length ?? 1)
    }
  }
})
